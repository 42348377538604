import { useRef, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import ReCAPTCHA from 'react-google-recaptcha';
import { PhoneNumber } from '../../fields/PhoneNumber';
import { FormInputField } from './FormInputField';

type SignUpFormProps = {
  messageButton?: string;
  onHandleFirstName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleLastName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandlePhoneNumber: (phone: string) => void;
  onHandleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleConfirmationEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCaptchaChange: () => void;
  errors: {};
  recaptchaRef: React.RefObject<ReCAPTCHA>;
  isCaptchaInvalid: boolean;
};

export const SignUpFormInputs = function ({
  messageButton,
  onHandleConfirmationEmail,
  onHandleEmail,
  onHandleFirstName,
  onHandleLastName,
  onHandlePassword,
  onHandlePhoneNumber,
  onCaptchaChange,
  recaptchaRef,
  errors,
  isCaptchaInvalid,
}: SignUpFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <FormInputField
          identifier="first_name"
          onChangeFn={onHandleFirstName}
          errors={errors}
          label="First Name"
          autoComplete="given-name"
          error={'first_name' in errors}
          helperText={'first_name' in errors ? errors['first_name'] : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputField
          identifier="last_name"
          onChangeFn={onHandleLastName}
          errors={errors}
          label="Last Name"
          autoComplete="family-name"
          error={"last_name" in errors}
          helperText={"last_name" in errors ? errors["last_name"] : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          identifier="email"
          onChangeFn={onHandleEmail}
          errors={errors}
          label="Email Address"
          autoComplete="email"
          type="email"
          error={"email" in errors}
          helperText={"email" in errors ? errors["email"] : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          identifier="email_confirmation"
          onChangeFn={onHandleConfirmationEmail}
          errors={errors}
          label="Confirm Email Address"
          autoComplete="confirmation-email"
          type="email"
          error={"email_confirmation" in errors}
          helperText={
            "email_confirmation" in errors ? errors["email_confirmation"] : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumber
          value={""}
          onChange={onHandlePhoneNumber}
          id="phone-number"
          fullWidth
          required
          error={"phone_number" in errors}
          helperText={"phone_number" in errors ? errors["phone_number"] : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputField
          identifier="password"
          onChangeFn={onHandlePassword}
          errors={errors}
          label="Password"
          autoComplete="password"
          type={showPassword ? "text" : "password"}
          error={"password" in errors}
          helperText={"password" in errors ? errors["password"] : ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <p style={{ color: "#e87f3e", fontWeight: "bold", marginTop: "0", textAlign: "left", fontSize: "0.8rem" }}>
        Confirmation email & SMS will be sent immediately. Please make sure your email & phone number are correct.
      </p>
      <Grid item xs={12}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY || ""}
          onChange={onCaptchaChange}
          ref={recaptchaRef}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isCaptchaInvalid}
          style={{
            marginTop: 10,
            marginBottom: 2,
            background: isCaptchaInvalid ? "rgba(0, 0, 0, 0.12)" : "#1E79CB",
          }}
        >
          {messageButton ? messageButton : "Continue"}
        </Button>
      </Grid>
    </Grid>
  );
};
